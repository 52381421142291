<header id="main-header">
  <nav class="navbar navbar-expand-lg py-4">
    <div class="container flex-lg-row flex-nowrap align-items-center position-relative">
      <div class="navbar-brand w-100 m-0">
        <a href="#inicio">
          <figure id="logotype" class="m-0 h-50" role="button">
            <img src="./assets/images/logotype-white.svg" alt="" class="w-100 h-100">
          </figure>
        </a>
      </div>

      <div class="navbar-other w-100 d-flex ms-auto">
        <ul class="navbar-nav flex-row align-items-center ms-auto">
          <li class="nav-item mx-2">
            <a href="#contacto" class="nav-link text-bg-dark px-4 rounded-2">Contacto</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>