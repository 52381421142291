<main-header></main-header>

<main class="wrapper">
  <section id="inicio" class="page text-bg-primary d-flex flex-column align-items-center justify-content-center">
    <figure class="background position-absolute start-0 top-0 w-100 h-100">
      <img src="./assets/images/hero.jpg" alt="" class="object-fit-cover w-100 h-100">
    </figure>

    <div class="container">
      <h1 class="section-title display-4">Invertimos para el futuro.</h1>
      <p class="fs-4 fw-light m-0">Octopus Capital es una compañía privada de inversión enfocada en la apreciación de
        capital para el largo plazo. Buscamos tener activos con un alto potencial de crecimiento.</p>
    </div>
  </section>

  <section id="historia" class="page text-bg-secondary d-flex flex-column align-items-center justify-content-center">
    <figure class="background position-absolute start-0 top-0 w-100 h-100">
      <img src="./assets/images/steven-ungermann-gpOfZeANy_k-unsplash.jpg" alt="" class="object-fit-cover w-100 h-100">
    </figure>
    <div class="container">
      <h1 class="section-title display-4">Nuestra historia.</h1>
      <p class="fs-4 fw-light m-0">Octopus Capital nace formalmente en 2019 con el objetivo de invertir en compañías en
        etapas iniciales, principalmente en Start Ups tecnológicas con el potencial de revolucionar sus respectivas
        industrias. Con el tiempo hemos evolucionado para convertirnos en una compañía con exposición a diversas clases
        de activos que agreguen valor a la sociedad y que van desde empresas públicas y privadas, hasta activos
        alternativos como Bitcoin.</p>
    </div>
  </section>

  <section id="filosofia" class="page text-bg-primary d-flex flex-column align-items-center justify-content-center">
    <figure class="background position-absolute start-0 top-0 w-100 h-100">
      <img src="./assets/images/marios-gkortsilas-OAePEg3V93A-unsplash.jpg" alt="" class="object-fit-cover w-100 h-100">
    </figure>
    <div class="container">
      <h1 class="section-title display-4">Nuestra folosofía.</h1>
      <p class="fs-4 fw-light m-0">La paciencia es nuestra principal virtud, sabemos que lo bueno lleva su tiempo y las
        grandes inversiones no se hacen de la noche a la mañana. Nuestro principal enfoque es de valor, lo que nos hace
        estar en constante búsqueda de activos subvalorados pero con sólidos fundamentales que nos puedan generar
        retornos extraordinarios a lo largo del tiempo. La curiosidad es uno de nuestros pilares, nunca dejamos de
        aprender algo nuevo.</p>
    </div>
  </section>

  <section id="valores" class="page text-bg-secondary d-flex flex-column align-items-center justify-content-center">
    <figure class="background position-absolute start-0 top-0 w-100 h-100">
      <img src="./assets/images/rebecca-hausner-pFKfvWAKEAc-unsplash.jpg" alt="" class="object-fit-cover w-100 h-100">
    </figure>
    <div class="container">
      <h1 class="section-title display-4">Nuestros valores.</h1>

      <ul>
        <li class="mb-2">
          <p class="fs-4 fw-light m-0"><strong>Honestidad:</strong> Siempre nos conducimos con la verdad, hablamos
            con claridad y sin rodeos, inclusive cuando se trata de cuestionar nuestras propias ideas.</p>
        </li>
        <li class="mb-2">
          <p class="fs-4 fw-light m-0"><strong>Integridad:</strong> Mantenemos los más altos estándares de ética y
            profesionalismo, siempre viendo por el bien de nuestros socios, colaboradores y nuestra comunidad.</p>
        </li>
        <li class="mb-2">
          <p class="fs-4 fw-light m-0"><strong>Disciplina:</strong> Estudiamos y analizamos cada detalle de nuestras
            decisiones de manera consistente.</p>
        </li>
        <li class="mb-2">
          <p class="fs-4 fw-light m-0"><strong>Humildad:</strong> Sabemos que no siempre estaremos en lo correcto, pero
            sabemos reconocer cuando es tiempo de dar un nuevo giro</p>
        </li>
        <li class="mb-2">
          <p class="fs-4 fw-light m-0"><strong>Respeto:</strong> Todas las ideas son bienvenidas, cada persona con la
            que trabajamos o convivimos es igual de importante para nosotros.</p>
        </li>
      </ul>
    </div>
  </section>

  <section id="quote" class="page text-bg-dark d-flex flex-column align-items-center justify-content-center">
    <div class="container position-relative">
      <div style="position: absolute; top: -140px; left: -100px; z-index: -1; opacity: 0.2;">
        <svg xmlns="http://www.w3.org/2000/svg" width="300" height="300" fill="currentColor" class="bi bi-quote"
          viewBox="0 0 16 16">
          <path
            d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388q0-.527.062-1.054.093-.558.31-.992t.559-.683q.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612q0-.527.062-1.054.094-.558.31-.992.217-.434.559-.683.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 3 7.558V11a1 1 0 0 0 1 1z" />
        </svg>
      </div>

      <h1 class="section-title display-4">"No es necesario hacer cosas extraordinarias para conseguir resultados
        extraordinarios".</h1>

      <p class="fs-5 fw-medium m-0">- Warren Buffett.</p>
    </div>
  </section>

  <section id="contacto" class="page text-bg-primary d-flex flex-column align-items-center justify-content-center">
    <figure class="background position-absolute start-0 top-0 w-100 h-100">
      <img src="./assets/images/priscilla-du-preez-7IcTZbH7s7g-unsplash.jpg" alt=""
        class="object-fit-cover w-100 h-100">
    </figure>
    <div class="container">
      <h1 class="section-title display-4">Contacto.</h1>
      <p class="fs-4 fw-light mb-4">Nos encanta discutir nuevas ideas con quien lo desee, no dudes en escribirnos si
        deseas aprender más sobre nuestra folosofía de inversión o sobre los libros que más nos han inspirado. Nuestro
        correo es <strong><a href="mailto:{{'hola@octopuscapital.com'}}"
            class="text-light">{{'hola@octopuscapital.com'}}</a></strong></p>

      <div class="d-flex align-items-center gap-2 mb-4">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-twitter-x"
          viewBox="0 0 16 16">
          <path
            d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
        </svg>

        <p class="fs-4 fw-light m-0">
          <a href="https://x.com/octopuscapital" target="_blank" class="text-light">{{'@OctopusCapital'}}</a>
        </p>
      </div>

      <p class="fs-4 fw-light mb-4">Chilam Balam 261, SM 50, Mz 22. Fracc. San Ángel, Cancún, QRoo, México. 77530.</p>

      <p class="fw-semibold mb-2">Aviso de responsabilidad.</p>
      <p class="fw-light mb-0"><small>Octopus Capital SAPI de CV es una compañía privada y no ofrece servicios de
          inversión al público en general. Lo descrito en esta página web no debe considerarse como recomendación de
          inversión, únicamente debe verse como materal ilustrativo.</small></p>
    </div>
  </section>
</main>